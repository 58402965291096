@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f1f9;
}

/* Import the font */
@font-face {
  font-family: 'MyFont';
  src:
    url('./public/inter-bold.woff2') format('woff2'),
    url('./public/inter-bold.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Use the font */
.baseneue {
  font-family:
    'MyFont',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.shadow-normal {
  transition: box-shadow 0.3s;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.shadow-hover {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}

.main-shadow {
  -webkit-box-shadow: 3px 3px 55px -1px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 3px 3px 55px -1px rgba(0, 0, 0, 0.36);
  box-shadow: 3px 3px 55px -1px rgba(0, 0, 0, 0.36);
  border-radius: 25px;
}

/* Add this inside your component or as a global style */
.button,
.card {
  /* Other styles */
  border-radius: 4px; /* for example, adjust as needed */
  transition: transform 0.3s ease-in-out;
  pointer-events: cursor;
}

.button:hover,
.card:hover {
  /* Other styles */
  transform: translateY(-2px); /* Slightly raise the element */
  transform: scale(1.05);
}
